import React, { useEffect, memo } from 'react';
import { FacebookProvider, Page } from 'react-facebook';
import JuicerFeed from 'react-juicer-feed';

const FacebookEmbed = memo((props) => {
    
    return (
        <JuicerFeed feedId='new-life-furniture-restoration' />
    );
});

export default FacebookEmbed;

/*
<script type="text/javascript" src="https://www.juicer.io/embed/new-life-furniture-restoration/embed-code.js" async defer></script>
*/