import React from "react";
import { useRef } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/signup-illustration.svg";
import logo from "images/logo-assets/logo-transparent-svg.svg";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as SubmitIcon } from "feather-icons/dist/icons/send.svg";
// import { useForm, SubmitHandler } from "react-hook-form";
// import { useForm } from "@formcarry/react";
import { useNavigate } from "react-router-dom";

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-gray-900 font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-primary-500 text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-auto mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-lg text-gray-900 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const TextArea = tw.textarea`resize-none w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Label = tw.label`mr-2 mb-4 leading-relaxed text-base text-gray-900 font-medium`;
const ColumnContainer = tw.div`flex flex-col `;

const Form = tw.form`mx-auto max-w-md`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const RadioInput = tw.input`mr-2 px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-secondary-700 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;

export default ({
  logoLinkUrl = "/",
  headingText = "Commission Form",
  submitButtonText = "Submit",
  SubmitButtonIcon = SubmitIcon
}) => {

  const formRef = useRef(null);
  const navigate = useNavigate();

  const sendFormData = async (e) => {
    e.preventDefault();

    if(!formRef.current) {
      console.log('An error occured with form ref.');
      return;
    }

    //retrieve form data
    const formData = new FormData(formRef.current);
    console.log(formData);
    
    await fetch('https://formcarry.com/s/qsaZCewYrd', {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json'
      }
    })
    .then(res => res.json())
    .then(res => {
      console.log(res);
    });

    navigate('/thankyou');
  }

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                <Form ref={formRef} onSubmit={sendFormData}>
                  <Input id="name" type="text" name="name" placeholder="First and Last Name" required />
                  <Input id="email" type="email" name="email" placeholder="Email" required />
                  <Input id="phone" type="tel" name="phone" placeholder="Phone Number" required />

                  <DividerTextContainer><DividerText>Which best explains your needs?</DividerText></DividerTextContainer>
                  <ColumnContainer>
                    <Label>
                      <RadioInput type="radio" name="Which best explains your needs?" value="I know exactly what I want." />
                      I know exactly how I want my furniture redone
                    </Label>

                      
                    <Label>
                      <RadioInput type="radio" name="Which best explains your needs?" value="I have an idea but might need a small amount of guidance." />
                      I have an idea but might need a small amount of guidance
                    </Label>

                    <Label>
                      <RadioInput type="radio" name="Which best explains your needs?" value="I need full design help. ($30)" />
                      I need full design help with paint color and hardware suggestions
                    </Label>
                  </ColumnContainer>
                  
                  <DividerTextContainer><DividerText>What kind of work would you like done?</DividerText></DividerTextContainer>

                  <ColumnContainer>
                    <Label htmlFor="painting">
                      <RadioInput id="painting" type="checkbox" name="What kind of work would you like done?" value="painting" />
                      Painting 
                    </Label>

                    
                    <Label htmlFor="refinishing">
                      <RadioInput id="refinishing" type="checkbox" name="What kind of work would you like done?" value="refinishing" />
                      Refinishing 
                    </Label>

                    
                    <Label htmlFor="minor-repairs">
                      <RadioInput id="minor-repairs" type="checkbox" name="What kind of work would you like done?" value="minor repairs" />
                      Minor Repairs 
                    </Label>

                    
                    <Label htmlFor="dog-kennel-conversion">
                      <RadioInput id="dog-kennel-conversion" type="checkbox" name="What kind of work would you like done?" value="dog kennel conversion" />
                      Dog Kennel Conversion 
                    </Label>
                  </ColumnContainer>

                  <DividerTextContainer><DividerText>Furniture Type</DividerText></DividerTextContainer>

                  <ColumnContainer>
                  
                    <Label htmlFor="dresser1">
                      <RadioInput id="dresser1" type="radio" name="Furniture Type" value="3-5 Drawer Dresser" />
                      3-5 Drawer Dresser
                    </Label>
                    
                    <Label htmlFor="dresser2">
                      <RadioInput id="dresser2" type="radio" name="Furniture Type" value="6+ Drawer Dresser" />
                      6+ Drawer Dresser
                    </Label>
                    
                    <Label htmlFor="desk">
                      <RadioInput id="desk" type="radio" name="Furniture Type" value="desk" />
                      Desk
                    </Label>
                    
                    <Label htmlFor="bed1">
                      <RadioInput id="bed1" type="radio" name="Furniture Type" value="Twin/Full bed" />
                      Bed - Twin/Full
                    </Label>

                    <Label htmlFor="bed2">
                      <RadioInput id="bed2" type="radio" name="Furniture Type" value="Queen/Full bed" />
                      Bed - Queen/King
                    </Label>

                    <Label htmlFor="headboard">
                      <RadioInput id="headboard" type="radio" name="Furniture Type" value="Headboard/Footboard" />
                      Headboard/Footboard
                    </Label>

                    <Label htmlFor="Nightstand/End Table">
                      <RadioInput id="Nightstand/End Table" type="radio" name="Furniture Type" value="Nightstand/End Table" />
                      Nightstand/End Table
                    </Label>

                    <Label htmlFor="Buffet/Sideboard">
                      <RadioInput id="Buffet/Sideboard" type="radio" name="Furniture Type" value="Buffet/Sideboard" />
                      Buffet/Sideboard
                    </Label>

                    <Label htmlFor="Console/Entryway Table">
                      <RadioInput id="Console/Entryway Table" type="radio" name="Furniture Type" value="Console/Entryway Table" />
                      Console/Entryway Table
                    </Label>

                    <Label htmlFor="Armoire">
                      <RadioInput id="Armoire" type="radio" name="Furniture Type" value="Armoire" />
                      Armoire
                    </Label>

                    <Label htmlFor="Vanity">
                      <RadioInput id="Vanity" type="radio" name="Furniture Type" value="Vanity" />
                      Vanity
                    </Label>

                    <Label htmlFor="Mirror">
                      <RadioInput id="Mirror" type="radio" name="Furniture Type" value="Mirror" />
                      Mirror
                    </Label>

                    <Label htmlFor="Coffee Table">
                      <RadioInput id="Coffee Table" type="radio" name="Furniture Type" value="Coffee Table" />
                      Coffee Table
                    </Label>

                    <Label htmlFor="Hutch">
                      <RadioInput id="Hutch" type="radio" name="Furniture Type" value="Hutch" />
                      Hutch
                    </Label>

                    <Label htmlFor="Table">
                      <RadioInput id="Table" type="radio" name="Furniture Type" value="Table" />
                      Table
                    </Label>
                  </ColumnContainer>
                    
                  

                  <DividerTextContainer><DividerText>Finishing Options</DividerText></DividerTextContainer>

                  <ColumnContainer>
                    
                    <Label htmlFor="stain">
                      <RadioInput id="stain" type="radio" name="Finishing Options" value="full stain" />
                      All Stained/Natural Wood
                    </Label>

                    <Label htmlFor="paint">
                      <RadioInput id="paint" type="radio" name="Finishing Options" value="full paint" />
                      All Painted
                    </Label>

                    <Label htmlFor="stain/paint">
                      <RadioInput id="stain/paint" type="radio" name="Finishing Options" value="partial paint/stain" />
                      Partial Stain/Partial Paint
                    </Label>

                    <Label htmlFor="notsure">
                      <RadioInput id="notsure" type="radio" name="Finishing Options" value="unsure" />
                      Not Sure
                    </Label>
                  </ColumnContainer>
                  
                  <DividerTextContainer><DividerText>Would you like new hardware installed?</DividerText></DividerTextContainer>

                  <Label htmlFor="yes">
                    <RadioInput id="yes" type="radio" name="Would you like new hardware installed?" value="New Hardware" />
                    Yes
                  </Label>

                    
                  <Label htmlFor="no">
                    <RadioInput id="no" type="radio" name="hardware" value="No New Hardware" />
                    No
                  </Label>

                  <Label htmlFor="idk">
                    <RadioInput id="idk" type="radio" name="hardware" value="unsure" />
                    Not Sure
                  </Label>

                  {/* <DividerTextContainer>Pictures<DividerText/></DividerTextContainer>
                  <Input type="file" id="pictureInput "name="picture" multiple required /> */}

                  <DividerTextContainer><DividerText>Misc Details</DividerText></DividerTextContainer>

                  
                  <Label htmlFor="misc">Put any additional details you would like to include below:</Label>
                  <TextArea 
                    id="misc"
                    name="additional details"
                    rows={4}
                    cols={30}
                  />
                  
                  <SubmitButton type="submit">
                    <SubmitButtonIcon className="icon" />
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton>

                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          {/* <IllustrationContainer>
            <IllustrationImage imageSrc={logo} />
          </IllustrationContainer> */}
        </Content>
      </Container>
    </AnimationRevealPage>
  );
}
